import { StringToAny } from '../../../types';
import { Errors } from '../../../utility/errors';
import { TypeUtility as TU } from '../../../utility/type';

//
//


const hasWorth = (fieldName: string, value: unknown): boolean => {
  return !fieldName.startsWith('_') && value !== null && value !== undefined;
}

const getCleanVersionOf = (filthy: StringToAny): StringToAny => {
  const ohbeejay = {} as StringToAny;
  for (const fieldName of Object.getOwnPropertyNames(filthy)) {
    const value: unknown = filthy[fieldName];
    if (hasWorth(fieldName, value)) {
      ohbeejay[fieldName] = value;
    }
  }
  return ohbeejay;
};


//
// the actual class
//

export class ResponseProbist {
  constructor(private requestName: string) {}

  // get the string value of a field by the field name
  getStringField(response: StringToAny, fieldName: string): string {
    
    const ohbeejay = this.extract(response);
    if (!Object.prototype.hasOwnProperty.call(ohbeejay, fieldName)) {
      throw Errors.noSuchKey(fieldName);
    }

    return ohbeejay[fieldName];
  }

  extractFieldAsObject(result: StringToAny, key: string): StringToAny {
    const wire: StringToAny = this.extract(result);
    if (!(key in wire)) {
      throw Errors.noSuchKey(key);
    }

    const value: unknown = wire[key];
    TU.verifyIsObjectLike(value);

    return value as StringToAny;
  }

  extractFieldAsArray(result: StringToAny, key: string): StringToAny[] {
    const wire: StringToAny = this.extract(result);
    if (!(key in wire)) {
      throw Errors.noSuchKey(key);
    }
  
    const value: unknown = wire[key];
    if (!(value instanceof Array)) {
      throw Errors.parse(value, 'array');
    }

    const filthyArray = value as StringToAny[];
    const cleanArray = [] as StringToAny[];
    for (const filthy of filthyArray) {
      cleanArray.push(getCleanVersionOf(filthy));
    }

    return cleanArray;
  }

  private static getCleanVersionOf(filthy: StringToAny): StringToAny {
    const ohbeejay = {} as StringToAny;
    for (const fieldName of Object.getOwnPropertyNames(filthy)) {
      const value: unknown = filthy[fieldName];
      if (hasWorth(fieldName, value)) {
        ohbeejay[fieldName] = value;
      }
    }
    return ohbeejay;
  }

  private extract(result: StringToAny): StringToAny {
    if (!Object.prototype.hasOwnProperty.call(result, 'data')) {
      throw Errors.noSuchKey('data');
    }

    const data = result.data as StringToAny;
    if (!Object.prototype.hasOwnProperty.call(data, this.requestName)) {
      throw Errors.noSuchKey(this.requestName);
    }
  
    return data[this.requestName] as StringToAny;
  } 

}
