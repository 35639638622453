

import { defineComponent, inject, ref } from 'vue';

//

import { StringToAny } from 'helpist/lib/types';

import { Result } from 'helpist/lib/traffic/result';
import { ResultCode } from 'helpist/lib/traffic/result/code';
import { Why } from 'helpist/lib/traffic/result/why';

//

import { AuthenticatioService } from '@/authenticatio/service';

//
//

const emailRegex = /^[a-z][a-z0-9._]+@[a-z0-9]+(\.[a-z0-9]+){1,}$/;

export default defineComponent({

  setup(): StringToAny {

    const service =
      inject(AuthenticatioService.InjectionKey) as AuthenticatioService;

    const sinepCode = ref('');
    const emailAddress = ref('');
    const userName = ref('');
    const password = ref('');
    const passwordTakeTwo = ref('');

    const allFieldsAreValid = ref(false);
    const errorExists = ref(false);
    const errorText = ref('');


    // handlers
    const handleCreateUserResult = (result: Result) => {
      if (result.code === ResultCode.Success) {
        window.location.href = '/';

      } else if (result.code === ResultCode.Failure) {

        if (result.why === Why.SinepCodeAlreadyRedeemed) {
          errorExists.value = true;
          errorText.value = 'that sinep code has already been redeemed =/'
        } else if (result.why === Why.SinepCodeHasExpired) {
          errorExists.value = true;
          errorText.value = 'that sinep code is expired =/'
        }

      } else {
        errorExists.value = true;
        errorText.value = 'the creator of this application is a bit of a tool'
      }
    };
    const handleSubmit = () => {
      const hashword = service.hashSlowly(password.value, userName.value);
      service.createUser(
          sinepCode.value, userName.value, emailAddress.value, hashword)
        .then(handleCreateUserResult);
    };

    const validate = () => {
      if (sinepCode.value.length < 20) {
        allFieldsAreValid.value = false;
      } else if (userName.value.length < 3) {
        allFieldsAreValid.value = false;
      } else if (password.value.length < 5) {
        allFieldsAreValid.value = false;
      } else if (passwordTakeTwo.value !== password.value) {
        allFieldsAreValid.value = false;
      } else if (!emailRegex.test(emailAddress.value)) {
        allFieldsAreValid.value = false;
      } else {
        allFieldsAreValid.value = true;
      }
    };


    return {
      allFieldsAreValid,
      errorExists,
      errorText,

      // the actual values
      emailAddress,
      password,
      passwordTakeTwo,
      sinepCode,
      userName,

      // functions
      handleSubmit,
      validate,
    } as StringToAny;
  },

});

