import axios from 'axios';

import * as CryptoJS from 'crypto-js';

//

import { StringToAny } from 'helpist/lib/types';

import {
  ResponseProbist as Probist
} from 'helpist/lib/traffic/graphql/response/probist';

import { Result } from 'helpist/lib/traffic/result';
import { parseWhy, Why } from 'helpist/lib/traffic/result/why';

//
//

const loginEndpoint = process.env.VUE_APP_LOGIN_URL || '';
const signupEndpoint = process.env.VUE_APP_SIGNUP_URL || '';

interface PostResponse {
  config: StringToAny;
  data: StringToAny;
  headers: StringToAny;
  request: StringToAny;
  status: number;
  statusText: string;
}

export class AuthenticatioService {
  static readonly InjectionKey = 'authenticatio-service';
  static readonly DomainName = 'fuckyou';

  readonly creationProbist = new Probist('createUser');
  readonly loginProbist = new Probist('logIn');


  async logIn(userName: string, hashword: string): Promise<Result> {
    const request = {
      userName,
      hashword,
      updationTimeMillis: (new Date()).getTime(),
    };
    const options = { withCredentials: true };

    try {
      const response: PostResponse =
        await axios.post(loginEndpoint, request, options);

      if (!('othertoken' in response.data)) {
        return Result.failure(Why.NoTokenReturned);
      }
      const token = response.data.othertoken;
      localStorage.setItem('othertoken', token);

    } catch (error) {
      console.log('error during attempted login: ' + error);
      const why: Why = parseWhy(error);
      return Result.failure(why);
    }

    return Result.success();
  }

  async createUser(
      sinepCode: string,
      userName: string,
      emailAddress: string,
      hashword: string): Promise<Result> {

    const request = {
      sinepCode,
      userName,
      emailAddress,
      hashword,
      creationTimeMillis: (new Date()).getTime(),
    };

    try {
      const response: PostResponse =
        await axios.post(signupEndpoint, request);

      if (!('othertoken' in response.data)) {
        return Result.failure(Why.NoTokenReturned);
      }
      const token = response.data.othertoken;
      localStorage.setItem('othertoken', token);

    } catch (error) {
      console.log('received error during signup: ' + error);
      const why: Why = parseWhy(error.why);
      return Result.failure(why);
    }

    return Result.success();
  }

  hashSlowly(password: string, salt: string): string {
    const hash: CryptoJS.lib.WordArray = CryptoJS.PBKDF2(
        password,
        AuthenticatioService.DomainName + salt,
        {
          keySize: 512 / 32,
          iterations: 1000,
        }
    );
    return hash.toString(CryptoJS.enc.Base64);
  }
}
