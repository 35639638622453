//
//
export var Why;
(function (Why) {
    Why[Why["BadCredentials"] = 0] = "BadCredentials";
    Why[Why["NoReasonFound"] = 1] = "NoReasonFound";
    Why[Why["NoTokenReturned"] = 2] = "NoTokenReturned";
    Why[Why["SinepCodeAlreadyRedeemed"] = 3] = "SinepCodeAlreadyRedeemed";
    Why[Why["SinepCodeHasExpired"] = 4] = "SinepCodeHasExpired";
    Why[Why["StaleUpdateAttempt"] = 5] = "StaleUpdateAttempt";
    Why[Why["UserNameAlreadyTaken"] = 6] = "UserNameAlreadyTaken";
})(Why || (Why = {}));
export const parseWhy = (error) => {
    // unauthorized
    if (error.response?.status === 401) {
        return Why.BadCredentials;
    }
    // not acceptable
    if (error.response?.status === 406) {
        return Why.UserNameAlreadyTaken;
    }
    const stir = error.message || '';
    console.log('the stir is ' + stir);
    if (stir.includes('SINEP_CODE_ALREADY_REDEEMED')) {
        return Why.SinepCodeAlreadyRedeemed;
    }
    if (stir.includes('SINEP_CODE_HAS_EXPIRED')) {
        return Why.SinepCodeHasExpired;
    }
    if (stir.includes('STALE_UPDATE_ATTEMPT')) {
        return Why.StaleUpdateAttempt;
    }
    return Why.NoReasonFound;
};
