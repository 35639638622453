
import { defineComponent } from 'vue';

//

import { StringToAny } from 'helpist/lib/types';

//

import TheNothing from './views/Nothing.vue';

//
//

export default defineComponent({
  components: {
    TheNothing,
  },

  setup(): StringToAny {
    return {} as StringToAny;
  },
});
