import { ResultCode } from './code';
//
export class Result {
    constructor(code, why) {
        this.code = code;
        this.why = why;
    }
    static failure(why) {
        return new Result(ResultCode.Failure, why);
    }
    static success() {
        return new Result(ResultCode.Success);
    }
}
