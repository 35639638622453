export class Errors {
    static hasContent(message) {
        return message !== undefined &&
            message !== null &&
            message.constructor === String &&
            message.length > 0;
    }
    /*
     * @param {object} EnumClass
     * @param {string} key
     */
    static enumKey(EnumClass, key) {
        const allKeys = [];
        for (const potentialKey in EnumClass) {
            if (isNaN(parseInt(potentialKey, 10))) {
                allKeys.push(potentialKey);
            }
        }
        return new Error('EnumStringException: key ' + key + ' is not one of '
            + JSON.stringify(allKeys));
    }
    /*
     * @param {object} EnumClass
     * @param {number} value
     */
    static enumValue(EnumClass, value) {
        const allIntValues = [];
        for (const key in EnumClass) {
            const value = EnumClass[key];
            if (typeof value === 'number') {
                allIntValues.push(value);
            }
        }
        return new Error('EnumValueException: could not parse '
            + value + ' as enum with values: ' + JSON.stringify(allIntValues));
    }
    /*
     * @param {string} message
     * @param {class} expected
     * @param {class} actual
     */
    static type(message, expected, actual) {
        const firstPart = this.hasContent(message)
            ? message + '\n'
            : '';
        const actualAsSting = actual !== null ? actual.name : 'null';
        return new Error('TypeException: '
            + firstPart
            + 'expected type: ' + expected + '\n'
            + 'actual   type: ' + actualAsSting);
    }
    static else() {
        return new Error('ElseException: should not have reached this else statement');
    }
    static incompatible(stir) {
        return new Error('IncompatibleException: ' + stir);
    }
    static noSuchKey(key) {
        return new Error('NoSuchKeyException: key ' + key + ' does not exist');
    }
    static noSuchDeepKey(deepKey) {
        return new Error('NoSuchDeepKeyException: deep key '
            + JSON.stringify(deepKey)
            + ' does not exist');
    }
    static noSuchMethod(methodName) {
        return new Error('NoSuchMethodException: method ' + methodName + ' does not exist');
    }
    static notImplemented(message) {
        return new Error('NotImplementedException: ' + message);
    }
    static outOfRange(thing, startOfRange, endOfRange) {
        return new Error(thing + ' is not in expected range [' + startOfRange + ', ' +
            endOfRange + ']');
    }
    static overwhelmed(message) {
        return new Error('OverwhelmedException: ' + message);
    }
    static parse(stir, hoped) {
        return new Error('ParseException: could not parse: "' + stir + '" as ' + hoped);
    }
    static nonsensical(message) {
        return new Error('NonsensicalException: ' + message);
    }
    static notEnoughStuff(message) {
        return new Error('NotEnoughStuffException: ' + message);
    }
    static unhandledCase(caseName) {
        return new Error('UnhandledCaseException: case not handled: ' + caseName);
    }
    static tooMuchStuff(message) {
        return new Error('TooMuchStuffException: ' + message);
    }
    static unhandledEnum(enumName) {
        return new Error('UnhandledEnumException: ' + enumName);
    }
    static unsupportedSituation(message) {
        return new Error('UnsupportedSituationException: ' + message);
    }
    static unwantedNull(message) {
        return new Error('UnwantedNullException: ' + message);
    }
    static wtf(message) {
        return new Error('WtfException: ' + message);
    }
    static youAreInbred(message) {
        return new Error('YouAreInbredException: ' + message);
    }
    static builder(className, stuff, missingFields) {
        return new Error('BuilderException: cannot build ' + className +
            ' because the following fields are missing: ' +
            JSON.stringify(missingFields) +
            '.  populated fields: ' + JSON.stringify(stuff));
    }
}
