

import { defineComponent, inject, ref } from 'vue';

//

import { StringToAny } from 'helpist/lib/types';

import { Result } from 'helpist/lib/traffic/result';
import { ResultCode } from 'helpist/lib/traffic/result/code';
import { Why } from 'helpist/lib/traffic/result/why';

//

import { AuthenticatioService } from '@/authenticatio/service';

//
//

export default defineComponent({

  setup(): StringToAny {

    const service =
      inject(AuthenticatioService.InjectionKey) as AuthenticatioService;

    const userName = ref('');
    const password = ref('');

    const allFieldsAreValid = ref(false);
    const shouldShowError = ref(false);
    const errorMessage = ref('');

    // handlers
    const handleResult = (result: Result) => {
      if (result.code === ResultCode.Success) {
        window.location.href = '/';
      } else {
        if (result.why === Why.BadCredentials) {
          errorMessage.value = 'user name or password was incorrect =/';
        } else {
          errorMessage.value = 'an error was happened';
        }
        shouldShowError.value = true;
        password.value = '';
      }
    };
    const handleSubmit = () => {
      const hashword = service.hashSlowly(password.value, userName.value);
      service.logIn(userName.value, hashword).then(handleResult);
    };

    const validate = () => {
      if (userName.value.length < 3) {
        allFieldsAreValid.value = false;
      } else if (password.value.length < 5) {
        allFieldsAreValid.value = false;
      } else {
        allFieldsAreValid.value = true;
      }
    };


    return {
      allFieldsAreValid,
      errorMessage,
      shouldShowError,

      // the actual values
      password,
      userName,

      // functions
      handleSubmit,
      validate,
    } as StringToAny;
  },

});

