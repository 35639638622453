import { Errors } from './errors';
//
//
export class TypeUtility {
    // TODO: make up one's mind about type vs class
    static typeOf(thing) {
        return thing.constructor;
    }
    static isOfClass(thing, targetClass) {
        return this.typeOf(thing) === targetClass;
    }
    static isEnumKey(EnumClass, key) {
        return EnumClass[key] !== undefined;
    }
    static isEnumValue(EnumClass, potential) {
        for (const key in EnumClass) {
            const value = EnumClass[key];
            if (typeof value === 'number' && value === potential) {
                return true;
            }
        }
        return false;
    }
    // TODO: rename this to existsAndIsOfClass
    static isOfClassAndExists(thing, targetClass) {
        return thing !== null
            && thing !== undefined
            && this.isOfClass(thing, targetClass);
    }
    /**
     * @param {a this} that - the this of the class
     * @param {Object} fields4u - field name to value (values to populate)
     * @param {iFisting} fisting - the field listing for the class
     */
    static construct(that, fields4u, fisting) {
        this.verifyTypes(fields4u, fisting);
        for (const fieldName of fisting.getNames()) {
            if (fisting.has(fieldName)) {
                const fieldValue = fields4u[fieldName];
                if (fieldValue !== undefined) {
                    that[fieldName] = fieldValue;
                }
            }
        }
    }
    /*
     * verifies that a thing is of a certain type.
     * example: expect(TU.verifyType(4, Number)).to.not.throw();
     */
    static verifyType(thing, expectedType) {
        if (!this.isOfClass(thing, expectedType)) {
            throw Errors.type('failed type verification', expectedType, this.typeOf(thing));
        }
    }
    static verifyEnumKey(EnumClass, thing) {
        const key = TypeUtility.verifyIsString(thing);
        if (!TypeUtility.isEnumKey(EnumClass, thing)) {
            throw Errors.enumKey(EnumClass, key);
        }
        return key;
    }
    static verifyEnumValue(EnumClass, thing) {
        const numby = TypeUtility.verifyIsNumber(thing);
        if (TypeUtility.isEnumValue(EnumClass, numby)) {
            return numby;
        }
        throw Errors.enumValue(EnumClass, thing);
    }
    static verifyIsArrayOfObjects(thing) {
        TypeUtility.verifyType(thing, Array);
        const asArray = thing;
        for (const item of asArray) {
            TypeUtility.verifyIsObjectLike(item);
        }
        return thing;
    }
    static verifyIsBoolean(thing) {
        TypeUtility.verifyType(thing, Boolean);
        return thing;
    }
    static verifyIsNumber(thing) {
        TypeUtility.verifyType(thing, Number);
        return thing;
    }
    static verifyIsString(thing) {
        TypeUtility.verifyType(thing, String);
        return thing;
    }
    static verifyIsStringArray(thing) {
        TypeUtility.verifyType(thing, Array);
        return thing;
    }
    static verifyIsObject(thing) {
        TypeUtility.verifyType(thing, Object);
        return thing;
    }
    static verifyIsObjectLike(thing) {
        if (typeof thing !== 'object') {
            throw Errors.type('failed type verification', Object, this.typeOf(thing));
        }
        return thing;
    }
    /*
     * verifies that all fields in fisting are present in fields4u
     * and that their types are correct.
     *
     * note: this method does not care if fields4u contains *more* fields
     *       than fisting.  this is because when a subclass's constructor
     *       calls super(fields), the super class's validation will be
     *       comparing all fields with its own fisting, which would cause
     *       failure if we were to enforce that kind of check.
     *
     * @param {Object} fields4u maps field names to values
     * @param {Object} fisting maps field names to field info
     * @throws {Error} if the types are off.
     */
    static verifyTypes(fields4u, fisting) {
        for (const fieldName of fisting.getNames()) {
            if (fisting.has(fieldName)) {
                const validator = fisting.get(fieldName).validator;
                const value = fields4u[fieldName];
                if (!validator.hasCorrectType(value)) {
                    const typeOfValue = this.isNotAThing(value) ? null : this.typeOf(value);
                    throw Errors.type('type validation failed for ' + fieldName, validator.getClass(), typeOfValue);
                }
            }
        }
    }
    static castOrThrow(thing, hopedClass) {
        if (TypeUtility.isNotAThing(thing)) {
            throw Errors.unwantedNull('cannot parse a non-thing');
        }
        if (!(thing instanceof hopedClass)) {
            throw Errors.parse(thing.toString(), hopedClass.prototype.costructor.name);
        }
        return thing;
    }
    static isNotAThing(thing) {
        return thing === null || thing === undefined;
    }
}
