

import {
  computed, ComputedRef, defineComponent, onMounted, onBeforeUnmount, ref, Ref,
} from 'vue';

//

import { StringToAny } from 'helpist/lib/types';

//

import LoginForm from '@/components/LoginForm.vue';
import SignupForm from '@/components/SignupForm.vue';
  
//  
//

export default defineComponent({

  setup(): StringToAny {

    const isSignupMode = ref(false);
    const formComponent = computed(() => {
      return isSignupMode.value ? SignupForm : LoginForm;
    });


    //
    // positioning
    //

    const viewportHeight = ref(1) as Ref<number>;
    const contentGap = 10;
    const headingHeight = ref(0) as Ref<number>;
    const formHeight = ref(0) as Ref<number>;
    const contentHeight = computed(() => {
      return headingHeight.value + contentGap + formHeight.value;
    });

    const minFreePixelsAtTop: ComputedRef<number> = computed(() => {
      return Math.round(contentHeight.value / 2);
    });

    const freeSpaceAtTop: ComputedRef<number> = computed(() => {
      const topHalfSpace: number = Math.round(viewportHeight.value / 2);
      const guess = topHalfSpace - contentHeight.value;

      return guess < minFreePixelsAtTop.value ?
        minFreePixelsAtTop.value : guess;
    });

    const headingStyle: ComputedRef<StringToAny> = computed(() => {
      const topStir = freeSpaceAtTop.value + 'px';
      return {
        top: topStir,
        'margin-bottom': contentGap + 'px',
      };
    });

    const formStyle: ComputedRef<StringToAny> = computed(() => {
      const topStir =
        freeSpaceAtTop.value + headingHeight.value + contentGap + 'px';
      return {
        top: topStir,
      };
    });

    const theHeadingArea = ref(null) as Ref<HTMLElement|null>;
    const theFormWrappist = ref(null) as Ref<HTMLElement|null>;

    const handleWindowResize = () => {
      viewportHeight.value = window.innerHeight;
    };


    //
    // lifecycle
    //
    onMounted(() => {
      headingHeight.value =
        theHeadingArea.value!.getBoundingClientRect().height;
      formHeight.value = theFormWrappist.value!.getBoundingClientRect().height;

      window.addEventListener('resize', handleWindowResize);

      handleWindowResize();
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleWindowResize);
    });


    //
    // to the template (to the wall)
    //
    return {
      isSignupMode,

      theHeadingArea,
      headingStyle,

      theFormWrappist,
      formStyle,
      formComponent,
    };
  },

});
