import { createApp } from 'vue'

//

import App from '@/App.vue'

import { AuthenticatioService } from '@/authenticatio/service';

//
//

const app = createApp(App);

const authenticatioService = new AuthenticatioService();
app.provide(AuthenticatioService.InjectionKey, authenticatioService);

app.mount('#app');
