<template>
<div class='signup-form'>

  <!-- error reporting -->
  <div class='errorea' v-if='errorExists'>
    <span> {{ errorText }} </span>
  </div>


    <!-- the form -->
  <form class='the-form' @submit.prevent='handleSubmit'>

    <div class='the-table'>

      <!-- sinep code -->
      <div class='label-cell'>
        <label class='label'> sinep code </label>
      </div>
      <div class='value-cell'>
        <input
          class = 'input'
          type = 'text'
          v-model = 'sinepCode'
          size = 20
          @input = 'validate' />
      </div>

      <!-- email address -->
      <div class='label-cell'>
        <label class='label'> email </label>
      </div>
      <div class='value-cell'>
        <input
          class = 'input'
          type = 'text'
          v-model = 'emailAddress'
          size = 20
          @input = 'validate' />
      </div>

      <!-- user name -->
      <div class='label-cell'>
        <label class='label'> user name </label>
      </div>
      <div class='value-cell'>
        <input
          class = 'input'
          type = 'text'
          v-model = 'userName'
          size = 20
          @input = 'validate' />
      </div>

      <!-- password -->
      <div class='label-cell'>
        <label class='label'> password </label>
      </div>
      <div class='value-cell'>
        <input
          class = 'input'
          type = 'password'
          v-model = 'password'
          size = 20
          @input = 'validate' />
      </div>

      <!-- password confirmation -->
      <div class='label-cell'>
        <label class='label'> ...and again </label>
      </div>
      <div class='value-cell'>
        <input
          class = 'input'
          type = 'password'
          v-model = 'passwordTakeTwo'
          size = 20
          @input = 'validate' />
      </div>

      <!-- submit button -->
      <transition name='grow'>
      <div v-show='allFieldsAreValid' class='button-div'>
          <button class='button'> submit </button>
      </div>
      </transition>

    </div>
  </form>

</div>
</template>

<!---------------------------------------------------------------------------->

<script lang='ts'>

import { defineComponent, inject, ref } from 'vue';

//

import { StringToAny } from 'helpist/lib/types';

import { Result } from 'helpist/lib/traffic/result';
import { ResultCode } from 'helpist/lib/traffic/result/code';
import { Why } from 'helpist/lib/traffic/result/why';

//

import { AuthenticatioService } from '@/authenticatio/service';

//
//

const emailRegex = /^[a-z][a-z0-9._]+@[a-z0-9]+(\.[a-z0-9]+){1,}$/;

export default defineComponent({

  setup(): StringToAny {

    const service =
      inject(AuthenticatioService.InjectionKey) as AuthenticatioService;

    const sinepCode = ref('');
    const emailAddress = ref('');
    const userName = ref('');
    const password = ref('');
    const passwordTakeTwo = ref('');

    const allFieldsAreValid = ref(false);
    const errorExists = ref(false);
    const errorText = ref('');


    // handlers
    const handleCreateUserResult = (result: Result) => {
      if (result.code === ResultCode.Success) {
        window.location.href = '/';

      } else if (result.code === ResultCode.Failure) {

        if (result.why === Why.SinepCodeAlreadyRedeemed) {
          errorExists.value = true;
          errorText.value = 'that sinep code has already been redeemed =/'
        } else if (result.why === Why.SinepCodeHasExpired) {
          errorExists.value = true;
          errorText.value = 'that sinep code is expired =/'
        }

      } else {
        errorExists.value = true;
        errorText.value = 'the creator of this application is a bit of a tool'
      }
    };
    const handleSubmit = () => {
      const hashword = service.hashSlowly(password.value, userName.value);
      service.createUser(
          sinepCode.value, userName.value, emailAddress.value, hashword)
        .then(handleCreateUserResult);
    };

    const validate = () => {
      if (sinepCode.value.length < 20) {
        allFieldsAreValid.value = false;
      } else if (userName.value.length < 3) {
        allFieldsAreValid.value = false;
      } else if (password.value.length < 5) {
        allFieldsAreValid.value = false;
      } else if (passwordTakeTwo.value !== password.value) {
        allFieldsAreValid.value = false;
      } else if (!emailRegex.test(emailAddress.value)) {
        allFieldsAreValid.value = false;
      } else {
        allFieldsAreValid.value = true;
      }
    };


    return {
      allFieldsAreValid,
      errorExists,
      errorText,

      // the actual values
      emailAddress,
      password,
      passwordTakeTwo,
      sinepCode,
      userName,

      // functions
      handleSubmit,
      validate,
    } as StringToAny;
  },

});

</script>

<!---------------------------------------------------------------------------->

<style scoped>

.signup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  min-height: 10rem;
}

.errorea {
  text-align: center;
  font-color: red;
  font-weight: bold;
}

.the-table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 0.5rem;
}

.label-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.value-cell {
  display: inline-block;
}

.input {
  width: auto;
}

.button-div {
  grid-column: span 2;

  display: flex;
  justify-content: center;
}

.button {
  margin-top: 0.5rem;

  background-color: orange;
  border: none;
  border-radius: 2px;

  color: black;
  font-size: 1.2rem;
}
.button:hover {
  background-color: rgba(255, 100, 0, 1);
}

.grow-enter-active,
.grow-leave-active {
  transition: all 0.1s linear;
}

.grow-enter-from,
.grow-leave-to {
  transform: scale(0);
}

</style>
